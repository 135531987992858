import routesConfig from '../../../../../utils/constants/routesConfig';
import {
  Account,
  Sellers,
  Arrivals,
  Establishments,
  Logout,
  Mappings,
  Reporting,
  Trackings,
  Support
} from '../../../assets/icons';

const menuItems = [
  {
    menuItemId: routesConfig.account.routeId,
    slug: routesConfig.account.slug,
    label: 'menu.account',
    icon: Account
  },
  {
    menuItemId: routesConfig.establishments.routeId,
    slug: routesConfig.establishments.slug,
    label: 'menu.establishments',
    icon: Establishments
  },
  {
    menuItemId: routesConfig.sellers.routeId,
    slug: routesConfig.sellers.slug,
    label: 'menu.sellers',
    icon: Sellers
  },
  {
    menuItemId: routesConfig.products.routeId,
    slug: routesConfig.products.slug,
    label: 'menu.products',
    icon: Mappings,
    isHiddenBadge: true
  },
  {
    menuItemId: routesConfig.arrivals.routeId,
    slug: routesConfig.arrivals.slug,
    label: 'menu.arrivals',
    icon: Arrivals,
    isHiddenBadge: true
  },
  {
    menuItemId: routesConfig.trackings.routeId,
    slug: routesConfig.trackings.slug,
    label: 'menu.trackings',
    icon: Trackings
  },
  {
    menuItemId: routesConfig.reporting.routeId,
    slug: routesConfig.reporting.slug,
    label: 'menu.reporting',
    icon: Reporting
  },
  {
    menuItemId: 'support',
    label: 'menu.support',
    icon: Support
  },
  {
    menuItemId: 'logout',
    label: 'menu.logout',
    icon: Logout
  }
];

export default menuItems;

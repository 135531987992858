const routesConfig = {
  account: {
    routeId: 'account',
    backendId: 'account',
    slug: 'account',
    permission: 'user',
    public: false
  },
  establishments: {
    routeId: 'establishments',
    backendId: 'establishments',
    slug: 'establishments',
    permission: 'admin',
    public: false
  },
  sellers: {
    routeId: 'sellers',
    backendId: 'sellers',
    slug: 'sellers',
    permission: 'user',
    public: false
  },
  products: {
    routeId: 'products',
    backendId: 'products',
    slug: 'products',
    permission: 'user',
    public: false
  },
  arrivals: {
    routeId: 'arrivals',
    backendId: 'bookings',
    slug: 'arrivals',
    permission: 'user',
    public: false
  },
  trackings: {
    routeId: 'trackings',
    backendId: 'bookingClassifications',
    slug: 'trackings',
    permission: 'user',
    public: false
  },
  reporting: {
    routeId: 'reporting',
    backendId: 'reporting',
    slug: 'reporting',
    permission: 'user',
    public: false
  },
  login: {
    routeId: 'login',
    backendId: 'login',
    slug: 'login',
    public: true
  },
  createAccount: {
    routeId: 'createAccount',
    backendId: 'createAccount',
    slug: 'create-account',
    public: true
  }
};

export default routesConfig;

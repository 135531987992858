import { Menu as MenuAntd } from 'antd';
import { useMemo } from 'react';
import useTranslation from '../../../../i18n/hooks/useTranslation';
import { isObjectWithValues } from '../../../../utils/functions/validator/objectValidator';
import Badge from '../../info/badge/Badge';

const { Item } = MenuAntd;

const MenuItem = props => {
  const { icon: Icon, label, menuItemId, badgeCounter, ...restProps } = props;
  const { translation } = useTranslation();
  const showBadge = useMemo(() => isObjectWithValues(badgeCounter), [
    badgeCounter
  ]);

  return (
    <Item
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...restProps}
      className="menu-item"
      key={menuItemId}
      icon={
        Icon && (
          <span className="anticon">
            <Badge visible={showBadge}>
              <Icon />
            </Badge>
          </span>
        )
      }
    >
      <span className="menu-item-label capitalize">{translation(label)}</span>
    </Item>
  );
};

export default MenuItem;

import { useMemo, useState } from 'react';

import { useRouter } from 'next/router';
import { Layout as LayoutAntd } from 'antd';
import publicRoutes from '../../../utils/constants/publicRoutes';
import Menu from '../menu/Menu';
import Header from './components/Header/Header';
import useClickAway from '../../../utils/hooks/useClickAway';
import useResponsive from '../../../utils/hooks/useResponsive';
import PageContent from './components/PageContent/PageContent';
import Head from '../head/Head';

const { Content } = LayoutAntd;

const Layout = ({ children }) => {
  const { route } = useRouter();
  const [collapsed, setCollapsed] = useState(true);
  const { isSmartDevices } = useResponsive();
  const basicLayout = useMemo(
    () => publicRoutes.some(publicRoute => `/${publicRoute}` === route),
    [route]
  );

  const canShowMenu = useMemo(() => !basicLayout, [basicLayout]);
  const canShowHeader = useMemo(() => route !== '/login', [route]);
  const siderRef = useClickAway(() => {
    if (isSmartDevices) {
      return;
    }
    setCollapsed(true);
  });

  return (
    <LayoutAntd className="layout main">
      <Head favicon="./favicon.png" />
      {canShowMenu && (
        <Menu
          collapsed={collapsed}
          onChangeCollapsed={setCollapsed}
          siderRef={siderRef}
        />
      )}
      <LayoutAntd>
        {canShowHeader && (
          <Header
            onClickMenu={() => setCollapsed(prev => !prev)}
            simple={basicLayout}
            collapsed={collapsed}
          />
        )}
        <Content className={`content ${basicLayout ? 'basic' : ''}`}>
          {children}
        </Content>
      </LayoutAntd>
    </LayoutAntd>
  );
};

export { Layout, PageContent };

export const loginHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json; charset=UTF-8'
};

export const loginApiParams = {
  method: 'POST'
};

export const forgotPasswordApiParams = {
  method: 'POST'
};

export const resetPasswordApiParams = {
  method: 'POST'
};

export const logoutApiParams = {
  method: 'POST',
  endpoint: 'logout'
};

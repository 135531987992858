import { useMemo, useCallback } from 'react';
import { useRouter } from 'next/router';
import { Layout, Menu as MenuAntd } from 'antd';
import MenuItem from './components/MenuItem';
import menuItems from './utils/constants/menuItems';
import useResponsive from '../../../utils/hooks/useResponsive';
import { isArray } from '../../../utils/functions/validator/arrayValidator';
import useRequest from '../../../utils/hooks/useRequest';
import Fetch from '../api/Fetch';
import successNotification from '../notifications/successNotification';
import useTranslation from '../../../i18n/hooks/useTranslation';
import useSelector from '../../../utils/hooks/useSelector';
import { currentUserIsAdminSelector } from '../../../redux/sections/shared/shared.selectors';
import routesConfig from '../../../utils/constants/routesConfig';
import { logoutApiParams } from '../../login/api/loginApiParams';
import useBadges from '../../../utils/hooks/useBadges';
import useLicences from '../../account/licenses/utils/hooks/useLicences';

const { Sider } = Layout;

const Menu = ({ onChangeCollapsed, collapsed, siderRef }) => {
  const { translation } = useTranslation();
  const router = useRouter();
  const { isSmartDevices } = useResponsive();
  const isAdmin = useSelector(currentUserIsAdminSelector);
  const { badges } = useBadges({ readManual: false });
  const { licensesActiveCounter } = useLicences();
  const { run: logout } = useRequest({
    manual: true,
    service: () => Fetch(logoutApiParams),
    onSuccess: () => {
      successNotification(translation('menu.logoutSuccess'));
      window.location.href = 'login';
    }
  });

  const onClickMenuItem = ({ keyPath }) => {
    const clickInLogout = isArray(keyPath) && keyPath.includes('logout');
    const clickOnSupport = isArray(keyPath) && keyPath.includes('support');

    switch (true) {
      case clickInLogout:
        logout();
        break;
      case clickOnSupport:
        window.open('https://deepupsell.com/support/');
        break;

      default:
        {
          const menuItemPath = keyPath
            .reverse()
            .reduce((path, menuId) => path.concat(`/${menuId}`), '');
          router.push(menuItemPath);
          onChangeCollapsed(true);
        }
        break;
    }
  };

  const defaultSelectedKeys = useMemo(() => {
    const { route } = router;
    const selectedMenuItem = menuItems.find(menuItem => {
      return route.search(menuItem.menuItemId) !== -1;
    });
    const defaultActiveKey = selectedMenuItem
      ? [selectedMenuItem.menuItemId]
      : [];
    return defaultActiveKey;
  }, [router]);

  const logoUrl = useMemo(() => {
    if (collapsed) {
      return '/logoSymbol.svg';
    }
    return '/logo.svg';
  }, [collapsed]);

  const canViewMenuItem = useCallback(
    ({ menuItemId }) => {
      if (licensesActiveCounter === 0) {
        return menuItemId === 'account' || menuItemId === 'logout';
      }
      if (routesConfig[menuItemId]?.permission === 'admin') {
        return isAdmin;
      }
      return true;
    },
    [isAdmin, licensesActiveCounter]
  );

  return (
    <Sider
      ref={siderRef}
      theme="light"
      collapsible
      collapsed={collapsed}
      collapsedWidth={isSmartDevices ? 0 : undefined}
      onCollapse={onChangeCollapsed}
      zeroWidthTriggerStyle={{ display: 'none' }}
      className={`menu-sider ${collapsed ? 'collapsed' : ''} ${
        isSmartDevices ? 'smart-devices' : ''
      }`}
    >
      <div className="menu">
        <MenuAntd
          mode="inline"
          theme="light"
          onClick={onClickMenuItem}
          defaultSelectedKeys={defaultSelectedKeys}
        >
          {menuItems.map(
            menuItem =>
              canViewMenuItem(menuItem) && (
                <MenuItem
                  key={menuItem.menuItemId}
                  menuItemId={menuItem.menuItemId}
                  label={menuItem.label}
                  icon={menuItem.icon}
                  badgeCounter={
                    menuItem.isHiddenBadge === true
                      ? null
                      : badges?.[routesConfig[menuItem.menuItemId]?.backendId]
                  }
                />
              )
          )}
        </MenuAntd>
        <div
          onClick={() => onChangeCollapsed(!collapsed)}
          className={`menu-logo ${collapsed ? 'collapsed' : ''}`}
          role="presentation"
        >
          <img className="logo" src={logoUrl} alt="DeepUpSell" />
        </div>
      </div>
    </Sider>
  );
};

export default Menu;

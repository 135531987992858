import React from 'react';
import PropTypes from 'prop-types';
import { Badge as BadgeAntd } from 'antd';

const Badge = ({ children, visible, size }) => {
  return (
    <div className="badge-wrapper">
      {visible && (
        <BadgeAntd className={`badge ${size}`} color="var(--primary-color)" />
      )}
      {children}
    </div>
  );
};

Badge.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool,
  size: PropTypes.string
};

Badge.defaultProps = {
  visible: false,
  size: 'small'
};

export default Badge;

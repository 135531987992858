import PropTypes from 'prop-types';
import { Layout } from 'antd';
import HeaderSimple from './components/HeaderSimple';
import useResponsive from '../../../../../utils/hooks/useResponsive';
import { Menu as MenuIcon, Close } from '../../../assets/icons';

const { Header: HeaderAntd } = Layout;

const Header = ({ simple, onClickMenu, collapsed }) => {
  if (simple) {
    return <HeaderSimple />;
  }

  const { isSmartDevices } = useResponsive();

  return (
    isSmartDevices && (
      <HeaderAntd className="header">
        <div
          className="menu-trigger-mobile"
          onClick={onClickMenu}
          role="presentation"
        >
          {collapsed ? <MenuIcon size="large" /> : <Close size="large" />}
        </div>
      </HeaderAntd>
    )
  );
};

Header.propTypes = {
  simple: PropTypes.bool
};
Header.defaultProps = {
  simple: false
};

export default Header;

import { Grid } from 'antd';
import { useMemo } from 'react';

const { useBreakpoint } = Grid;
const useResponsive = () => {
  const screens = useBreakpoint();
  const { xs, sm, md, lg, xl, xxl } = screens;
  const isXxlScreen = useMemo(() => xxl, [screens]);
  const isXlScreen = useMemo(() => xl && !isXxlScreen, [screens]);
  const isLgScreen = useMemo(() => lg && !isXlScreen, [screens]);
  const isMdScreen = useMemo(() => md && !isLgScreen, [screens]);
  const isSmScreen = useMemo(() => sm && !isMdScreen, [screens]);
  const isXsScreen = useMemo(() => xs, [xs]);
  const isSmartDevices = useMemo(
    () => !(isXxlScreen || isXlScreen || isLgScreen || isMdScreen),
    [isXxlScreen, isXlScreen, isLgScreen, isMdScreen]
  );

  return {
    screens,
    isXsScreen,
    isSmScreen,
    isMdScreen,
    isLgScreen,
    isXlScreen,
    isXxlScreen,
    isSmartDevices
  };
};

export default useResponsive;

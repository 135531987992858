import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Select from '../select/Select';

const LanguageSelect = ({
  label,
  value,
  onChange,
  editModeFixed,
  canEdit,
  type
}) => {
  const router = useRouter();
  const options = useMemo(
    () => [
      { label: 'es', value: 'es' },
      { label: 'en', value: 'en' },
      { label: 'pt', value: 'pt' }
    ],
    []
  );

  const handleOnChange = newValue => {
    if (type === 'public') {
      router.push('', '', { locale: newValue });
    }
    if (onChange) {
      onChange(newValue);
    }
  };
  const valueSelect = useMemo(() => value || router?.locale, [router, value]);
  return (
    <Select
      label={label}
      options={options}
      editModeFixed={editModeFixed}
      autoFocus={false}
      value={valueSelect}
      onChange={handleOnChange}
      canEdit={canEdit}
    />
  );
};

LanguageSelect.propTypes = {
  editModeFixed: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  canEdit: PropTypes.bool,
  label: PropTypes.string
};
LanguageSelect.defaultProps = {
  editModeFixed: false,
  canEdit: false,
  onChange: undefined,
  type: 'public',
  value: undefined,
  label: undefined
};

export default LanguageSelect;

import LanguageSelect from '../../../../inputs/languageSelect/LanguageSelect';

const HeaderSimple = () => {
  return (
    <div className="header header-simple">
      <div className="header-simple-content">
        <div className="logo-wrapper">
          <img className="logo" src="/logo.svg" alt="DeepUpSell" />
        </div>
        <div className="language-wrapper">
          <LanguageSelect type="public" canEdit editModeFixed />
        </div>
      </div>
    </div>
  );
};

export default HeaderSimple;

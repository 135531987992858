import { useUpdateEffect } from 'ahooks';
import { useMemo } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import useTranslation from '../../../../../i18n/hooks/useTranslation';
import { setLicensesActionCreator } from '../../../../../redux/sections/shared/shared.actionCreators';
import { licensesActiveSelector } from '../../../../../redux/sections/shared/shared.selectors';
import { isArrayWithItems } from '../../../../../utils/functions/validator/arrayValidator';
import { isFunction } from '../../../../../utils/functions/validator/functionValidator';
import useRequest from '../../../../../utils/hooks/useRequest';
import useSelector from '../../../../../utils/hooks/useSelector';
import Fetch from '../../../../shared/api/Fetch';
import successNotification from '../../../../shared/notifications/successNotification';
import {
  readLicensesApiParams,
  readLicensesActiveApiParams
} from '../../api/licensesApiParams';
import errorNotification from '../../../../shared/notifications/errorNotification';

const useLicences = (props = {}) => {
  const { onSuccessAddLicences, stripe, elements } = props;
  const { translation } = useTranslation();
  const licensesActive = useSelector(licensesActiveSelector);
  const { data: licensesProducts, isLoading: isLoadingReadLicencesProducts } =
    useRequest({
      service: () =>
        Fetch({
          ...readLicensesApiParams
        }),
      fetchKey: 'readLicenses'
    });
  const {
    data,
    isLoading: isLoadingReadLicencesActive,
    run: readLicenses
  } = useRequest({
    service: () =>
      Fetch({
        ...readLicensesActiveApiParams
      })
  });
  useUpdateEffect(() => {
    setLicensesActionCreator(data);
  }, [data]);

  const handleAddLicensesOnSuccess = async ({
    response = {},
    checkPaymentIntent
  }) => {
    const { paymentIntentClientSecret, status } = response;
    if (status === 'requires_action') {
      const payload = await stripe.confirmCardPayment(
        paymentIntentClientSecret,
        {
          payment_method: {
            card: elements.getElement(CardElement)
          }
        }
      );
      if (payload.error) {
        errorNotification(payload.error?.message);
      } else {
        checkPaymentIntent({ paymentIntentId: payload?.paymentIntent?.id });
      }
    } else {
      readLicenses();
      successNotification(translation('account.licenses.updateSuccess'));
      if (isFunction(onSuccessAddLicences)) {
        onSuccessAddLicences();
      }
    }
  };

  const { run: checkPaymentIntent } = useRequest({
    manual: true,
    service: ({ paymentIntentId }) =>
      Fetch({
        method: 'GET',
        endpoint: 'stripePaymentIntent',
        urlParams: `?paymentIntentId=${paymentIntentId}`
      }),
    onSuccess: response =>
      handleAddLicensesOnSuccess({ response, checkPaymentIntent })
  });

  const { isLoading: isLoadingAddLicences, run: addLicences } = useRequest({
    service: fetchConfig =>
      Fetch({
        method: 'POST',
        endpoint: 'stripe',
        ...fetchConfig
      }),
    onSuccess: response =>
      handleAddLicensesOnSuccess({ response, checkPaymentIntent }),
    manual: true
  });
  const licensesActiveCounter = useMemo(() => {
    if (!isArrayWithItems(licensesActive)) {
      return 0;
    }
    const [licenseActive] = licensesActive;
    return licenseActive.quantity;
  }, [licensesActive]);

  const responseHook = useMemo(
    () => ({
      licensesProducts,
      licensesActive: licensesActive || data,
      addLicences,
      isLoadingAddLicences,
      isLoadingReadLicencesProducts,
      isLoadingReadLicencesActive,
      licensesActiveCounter,
      readLicenses
    }),
    [
      licensesProducts,
      licensesActive,
      data,
      addLicences,
      isLoadingAddLicences,
      isLoadingReadLicencesProducts,
      isLoadingReadLicencesActive,
      licensesActiveCounter,
      readLicenses
    ]
  );
  return responseHook;
};

export default useLicences;
